.App{
  padding:20px;
  background-image: linear-gradient(35deg, #3E5151,#DECBA4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.overFloat{
  z-index: 50000;
}
.flexBox{
  padding: 5px;
  width: auto;
  height: 100%;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50%;
}
.gap{
  margin: 5px;
}
.frosty {
  margin:15px;
  border-radius: 13px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(5px 5px 9px rgb(141, 141, 141));
  justify-content: center;
  align-items: center;
}